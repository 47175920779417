<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Work</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table-component
                :columns="columns"
                :rows="logs"
                :sortable="sortable"
                @sorting="sorting"
                :pagination="pagination"
                @search="search"
                @pageChanged="pageChanged">

                <template v-slot:topLeft>
                  <button class="btn btn-primary"
                          v-if="!workRunning"
                          @click="start">
                    <i class="fas fa-clock"></i> Check In
                  </button>

                  <button class="btn btn-danger"
                          v-else
                          @click="end">
                    <i class="fas fa-clock"></i> Check Out
                  </button>
                </template>

                <template v-slot:action="{ row }">
                  <a type="button"
                     v-if="row.this_week"
                     @click="$refs.workEditModal.show(row)"
                     class="btn btn-sm btn-warning mr-1">
                    <i class="fas fa-edit"></i>
                  </a>
                </template>
              </table-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <work-edit-modal @updated="loadData"
                   ref="workEditModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import WorkEditModal from "@/views/work/WorkEditModal.vue";
export default {
  components: {TableComponent, WorkEditModal},
  data() {
    return {
      logs: [],
      workRunning: false,
      queryParams: {
        search: '',
        search_columns: '',
        page: 1,
        sort: 'start',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'start',
        sort: 'desc'
      },
      columns: [
        {
          label: "Start",
          field: "start",
          sortable: true,
          searchable: false,
        },
        {
          label: "End",
          field: "end",
          sortable: true,
          searchable: false,
        },
        {
          label: "Worked",
          field: "worked",
          sortable: false,
          searchable: false,
        },
        {
          label: "Personal",
          field: "personal",
          sortable: false,
          searchable: false,
        },
        {
          label: "Client",
          field: "client",
          sortable: false,
          searchable: false,
        },
        {
          label: "Communication",
          field: "communication",
          sortable: false,
          searchable: false,
        },
        {
          label: "Power Score",
          field: "power_score",
          sortable: false,
          searchable: false,
        },
        {
          label: "Comment",
          field: "comment",
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-right']
        },
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
    this.getWorkingStatus();
  },
  methods: {
    loadData() {
      this.logs = [];

      this.axios.get('/work-log', {params: this.queryParams})
        .then((response) => {
          this.logs = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    getWorkingStatus() {
      this.axios.get('/work-log/status')
        .then(res => {
          this.workRunning = res.data;
        })
    },
    start() {
      this.$swal({
        title: 'Are you sure want to check-in?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/work-log/start').then(() => {
            this.getWorkingStatus();
            this.loadData();
            this.$swal.fire(
              'Checked In',
              'You have been checked in!',
              'success'
            )
          });
        }
      });
    },
    end() {
      this.$swal({
        title: 'Are you sure want to check-out?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/work-log/stop').then(() => {
            this.getWorkingStatus();
            this.loadData();
            this.$swal.fire(
              'Checked Out',
              'You have been checked out!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>