<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Notifications</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table-component
                :columns="columns"
                :rows="notifications"
                :sortable="sortable"
                @sorting="sorting"
                :pagination="pagination"
                @search="search"
                @pageChanged="pageChanged">
              </table-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TableComponent from "@/components/TableComponent";
export default {
  components: {TableComponent},
  data() {
    return {
      notifications: [],
      queryParams: {
        search: '',
        search_columns: '',
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          searchable: true,
        },
        {
          label: "Notification",
          field: "notification",
          sortable: false,
          searchable: false,
        },
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.notifications = [];

      this.axios.get('/notifications/all', {params: this.queryParams})
        .then((response) => {
          this.notifications = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>